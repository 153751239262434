@font-face {
  font-family: 'DomaineSansDisplay-Bold';
  src: local('DomaineSansDisplay-Bold'),url(./fonts/DomaineSansDisplay-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'DomaineSansDisplay-SemiBold';
  src: local('DomaineSansDisplay-SemiBold'),url(./fonts/DomaineSansDisplay-Semibold.otf) format('opentype');
}
@font-face {
  font-family: 'DomaineSansText-Black';
  src: local('DomaineSansText-Black'),url(./fonts/DomaineSansText-Black.otf) format('opentype');
}
@font-face {
  font-family: 'DomaineSansText-Light';
  src: local('DomaineSansText-Light'),url(./fonts/DomaineSansText-Light.otf) format('opentype');
}
@font-face {
  font-family: 'DomaineSansText-Regular';
  src: local('DomaineSansText-Regular'),url(./fonts/DomaineSansText-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'DomaineSansText-RegularItalic';
  src: local('DomaineSansText-RegularItalic'),url(./fonts/DomaineSansText-RegularItalic.otf) format('opentype');
}

@font-face {
  font-family: 'DomaineSansText-Medium';
  src: local('DomaineSansText-Medium'),url(./fonts/DomaineSansText-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'DomaineSansText-Bold';
  src: local('DomaineSansText-Bold'),url(./fonts/DomaineSansText-Bold.otf) format('opentype');
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  top: 10px;
}

.ant-select-single {
  height: auto;
}

.ant-btn {
  font-family: 'DomaineSansText-Light';
}



