/* Barner */
@media screen and (max-width: 600px) {
  .left-container,
  .btn-scroll {
    display: none !important;
  }
  .right-container {
    height: 352px !important;
  }
  .slide-img {
    background-size: cover !important;
  }
  .slide-img:last-child {
    background-size: contain !important;
  }
  .indicator-container {
    bottom: 45vh !important;
  }
  .indicator-line {
    width: 8px !important;
    height: 2px !important;
  }
  .indicator-number {
    font: normal bold 9px/11px DomaineSansText-Regular !important;
  }
}

/* Ads */
@media screen and (max-width: 600px) {
  .ads-text {
    font: italic normal 10px/18px DomaineSansText-Light !important;
  }
  .loud-text {
    font: normal bold 10px/18px DomaineSansText-RegularItalic !important;
  }
  .btn-shop {
    background: #800000;
    color: #fff !important;
    font: italic normal 10px/13px DomaineSansText-Light !important;
    height: 37px !important;
    margin-left: 16px;
    margin-top: 0px !important;
    width: 91px !important;
    position: relative !important;
  }
}

/* Shipping */
@media screen and (max-width: 600px) {
  .shipping-container {
    display: none !important;
  }
}

/* Showcase 1 && Showcase 2 */
@media screen and (max-width: 600px) {
  .showcase-container,
  .showcase2-container {
    display: block !important;
    padding: 40px 16px !important;
    height: auto !important;
  }
  .heading-text {
    font: normal normal 10px/13px DomaineSansText-Regular !important;
  }
  .subheading {
    font: normal bold 22px/23px DomaineSansDisplay-SemiBold !important;
  }
  .btn-shop-category {
    display: none !important;
  }
  .image-container {
    display: none !important;
  }

  .mobile-image-container {
    align-items: flex-end;
    display: flex !important;
    justify-content: space-between;
    margin-top: 33px;
    width: 100%;
  }
  .image1-content,
  .showcase2-image1-content {
    background-size: cover !important;
    height: 234px !important;
    margin-top: 0px !important;
    width: 11rem !important;
  }
  .image2-content,
  .showcase2-image2-content {
    background-size: cover !important;
    width: 186px !important;
    height: 248px !important;
  }
  .sale-text {
    font: normal bold 8px/10px DomaineSansText-Regular !important;
    padding: 5px 13px !important;
    top: 8px !important;
    left: 8px !important;
  }
  .details-1-container::before,
  .details-2-container::before {
    content: "";
    background: #000;
    opacity: 0.4;
    height: 56px !important;
    position: absolute;
    width: 11rem;
    top: 61.5rem;
  }
  .details-2-container::before {
    width: 11.6rem;
  }
  .details-3-container::before,
  .details-4-container::before {
    content: "";
    background: #000;
    opacity: 0.4;
    height: 56px !important;
    position: absolute;
    width: 11rem;
    top: 87rem;
  }
  .details-4-container::before {
    width: 11.6rem;
  }
  .details-1 {
    position: relative;
    padding: 5px 10px;
    top: 11.5rem;
  }
  .details-2 {
    padding-left: 5px;
    position: relative;
    top: 12.8rem;
  }
  .details-3 {
    padding-left: 5px;
    position: relative;
    top: 11.5rem;
  }
  .details-4 {
    padding-left: 5px;
    position: relative;
    top: 12.5rem;
  }
  .p_category {
    color: #fff !important;
    font: normal bold 8px/10px DomaineSansText-Regular !important;
  }
  .p_name {
    color: #fff !important;
    font: normal bold 11px/16px DomaineSansText-Regular !important;
  }
  .old_price {
    color: #fff !important;
    font: normal bold 9px/12px DomaineSansText-Regular !important;
  }
  .new_price {
    color: #fff !important;
    font: normal bold 11px/14px DomaineSansText-Regular !important;
  }
  .img1-buttons {
    position: relative;
    justify-content: space-evenly;
    display: flex !important;
    top: 13rem !important;
    width: 100% !important;
  }
  .img2-buttons {
    position: relative;
    justify-content: space-evenly;
    display: flex !important;
    top: 14.5rem !important;
    width: 100% !important;
  }
  .img3-buttons {
    position: relative;
    justify-content: space-evenly;
    display: flex !important;
    top: 13.5rem !important;
    width: 100% !important;
  }
  .img4-buttons {
    position: relative;
    justify-content: space-evenly;
    display: flex !important;
    top: 14.5rem !important;
    width: 100% !important;
  }
  .btn-wish,
  .btn-quick {
    font: normal bold 9px/12px DomaineSansText-Regular !important;
    height: 37px !important;
    width: 5rem !important;
  }
}
@media screen and (max-width: 400px) {
  .showcase-container,
  .showcase2-container {
    display: block !important;
    padding: 40px 5px 88px 5px !important;
  }
  .image1-content,
  .showcase2-image1-content {
    width: 10rem !important;
  }
  .image2-content,
  .showcase2-image2-content {
    width: 11rem !important;
  }
  .details-3-container::before,
  .details-4-container::before {
    top: 90rem;
  }
  .details-1-container::before,
  .details-3-container::before {
    width: 10rem;
  }
  .details-2-container::before,
  .details-4-container::before {
    width: 11rem;
  }
  .btn-wish,
  .btn-quick {
    font: normal bold 8px/12px DomaineSansText-Regular !important;
    width: 4.5rem !important;
  }
  .img2-buttons {
    top: 13.5rem !important;
  }
}

/* Best Sellers */
@media screen and (max-width: 600px) {
  .sellers {
    background: #fff !important;
    margin: 35px 10px;
    padding-left: 0px !important;
  }
  .sellers-text {
    font: normal bold 24px/31px DomaineSansText-Regular !important;
  }
  .product-content {
    background-size: cover !important;
    margin-top: 0px !important;
    height: 230px !important;
  }
  .seller-category {
    font: normal normal 10px/13px DomaineSansText-Regular !important;
  }
  .seller-name {
    font: normal bold 14px/23px DomaineSansText-Regular !important;
  }
  .seller-new-price {
    font: normal bold 13px/17px DomaineSansText-Regular !important;
  }
  .seller-sale-text {
    font: normal bold 8px/10px DomaineSansText-Regular !important;
    top: 8px !important;
    left: 8px !important;
  }
  .seller-details {
    height: auto !important;
    margin-top: 16px !important;
  }
  .seller-buttons {
    display: none !important;
  }
}

/* Social media page */
@media screen and (max-width: 600px) {
  .social-media-container {
    display: none !important;
  }
}

/* Trends */
@media screen and (max-width: 600px) {
  .trend-container {
    padding: 0px !important;
    margin-bottom: 30px !important;
  }
  .trend-title {
    font: normal 700 24px/31px DomaineSansText-Regular !important;
  }
  .trend-description {
    font: normal normal 8px/10px DomaineSansText-Regular !important;
  }
  .trend-card,
  .trend-details {
    height: 180px !important;
  }
  .losode-text {
    font: normal normal 10px/13px DomaineSansText-Light !important;
    margin-bottom: 0px !important;
  }
  .trend-text {
    font: normal bold 18px/23px DomaineSansText-Light !important;
    width: 90% !important;
  }
  .btn-trend {
    height: 37px !important;
    width: 91px !important;
  }
  .btn-trend.ant-btn .anticon {
    display: none !important;
  }
  .info-container {
    justify-content: center !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .info-div {
    display: grid;
    place-items: center;
    justify-content: space-between;
    height: 200px;
    margin-top: 56px;
  }
  .info-title {
    font: normal bold 18px/23px DomaineSansText-Regular;
    color: #000000;
  }
  .info-desc {
    font: normal normal 14px/18px DomaineSansText-Regular;
    color: #000000;
  }
  .sh-line {
    border: 0.5px solid #d4d4d4;
    display: block !important;
    height: 0px;
    width: 196px;
  }
  .lng-line {
    border: 0.5px solid #d4d4d4;
    display: block !important;
    height: 0px;
    width: 375px;
  }
}

/* Brands */

@media screen and (max-width: 600px) {
  .brands-container {
    background: #fff !important;
    padding-bottom: 5em;
  }

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px;
  }
  .brand-card {
    width: 100% !important;
  }

  .brand-description {
    font: normal normal 12px/16px DomaineSansText-Regular !important;
    margin-bottom: 52px !important;
  }
  .btn-brand {
    display: none !important;
  }
}

/* Register */
@media screen and (max-width: 600px) {
  .div-container {
    background-image: url("https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1642599094/website-pictures-optimized/wom2_hsu1uf_b5j2j3.jpg");
    background-size: contain;
    display: block !important;
    margin-top: 26px;
    padding: 18px 11px;
  }
  .register-container {
    background: #fff;
    margin-top: 0 !important;
    padding: 18px 21px;
    width: 95vw !important;
  }
  .txt-register {
    font: normal bold 24px/31px DomaineSansText-Regular !important;
  }
  .txt-setup {
    font: normal normal 11px/14px DomaineSansText-Regular !important;
  }
  .inputs-container {
    width: 100% !important;
  }
  /* .input,.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector,.btn-register, .date{
      font:normal normal 10px/14px DomaineSansText-Light ;
      height: 46px ;
      margin-top: 8.76px 
     
    } */

  /* .ant-input:placeholder-shown,.ant-picker-input > input:placeholder-shown{
      font:normal normal 11px/14px DomaineSansText-Regular;
      color: #32353C;
    } */
  .new-address-container
    .ant-select-single.ant-select-show-arrow
    .ant-select-selection-placeholder {
    font: normal normal 11px/14px DomaineSansText-Regular !important;
    padding: 15px 10px !important;
  }
  /* .ant-select-arrow{
      top:64%;
      height:10px;
      width: 16px;
    } */
  .btn-register {
    font: normal normal 14px/18px DomaineSansText-Regular !important;
    color: #fff !important;
  }
  .txt-signin {
    font: normal normal 11px/14px DomaineSansText-Regular !important;
  }
  .txt-terms {
    font: normal normal 8px/10px DomaineSansText-Regular !important;
  }
}

/* Sign In */
/* @media screen and (max-width: 600px) {
    .div-signin{
      background-image: url("https://res.cloudinary.com/losode-ng/image/upload/q_auto:eco/v1642599094/website-pictures-optimized/wom2_hsu1uf_b5j2j3.jpg");
      background-size: contain;
      display: block !important;
      margin-top: 26px;
      margin-bottom: 46px !important;
      padding:18px 11px;
    }
  } */

/* Register Seller */

@media screen and (max-width: 600px) {
  .register-seller-container {
    display: block !important;
  }
  .seller-info-div {
    width: 100vh !important;
    margin: 18px 7px !important;
  }
  .overlay {
    height: auto !important;
    padding-bottom: 12px !important;
    top: 5em !important;
    width: 92% !important;
  }
  .title-div {
    height: 36px !important;
    margin-top: 1em !important;
    width: 191px !important;
  }
  .title-text,
  .desc,
  .requirement-text {
    font: normal normal 11px/14px DomaineSansText-Regular !important;
  }
  .icon-div {
    align-items: center;
    width: 80px !important;
  }
  .icon {
    height: 43px !important;
    width: 43px !important;
  }
  .desc {
    text-align: center;
  }
  .requirements-div {
    margin-top: 24px !important;
  }
  .img-1 {
    background-size: contain !important;
    height: 470px !important;
  }
  .img-2 {
    display: none;
  }

  .regiseter-seller-contents {
    background: #fff;
    padding: 18px 21px;
  }
}

/* Men's page && women's page */

@media screen and (max-width: 600px) {
  .men-page-container,
  .women-page-container {
    margin-bottom: 0px !important;
  }
  .cta-container {
    display: block !important;
    margin: 36px 26px !important;
    height: auto !important;
  }
  .cta-title,
  .trends-title {
    font: normal 600 32px/50px DomaineSansText-Regular !important;
    width: 100% !important;
  }
  .cta-desc,
  .trends-desc {
    font: normal 600 13px/17px DomaineSansText-Regular !important;
    width: 100% !important;
  }
  .left-div {
    background-size: cover !important;
    background-position: center !important;
    height: 362px;
  }
  .right-div {
    margin-left: 0px !important;
  }
  .btn-cta {
    color: #fff !important;
    font: normal 600 8px/10px DomaineSansText-Regular !important;
    height: 37px !important;

    width: 126px !important;
  }
  .btn-cta-icon {
    margin-right: 14px !important;
    margin-top: 0px !important;
  }

  .new-items-container,
  .trending-container {
    display: block !important;
    height: auto !important;
    margin: 36px 26px !important;
  }
  .new-items-text-container-women {
    height: auto !important;
  }
  .text-new-num {
    font: normal normal 14px/18px DomaineSansText-Regular !important;
  }
  .text-new-title {
    font: normal 600 64px/89px DomaineSansText-Regular !important;
  }
  .text-new-title-women {
    font: normal 600 24px/33px DomaineSansText-Regular !important;
    margin-bottom: 0px !important;
  }
  .text-new-desc {
    font: normal normal 10px/13px DomaineSansText-Regular !important;
    width: 201px !important;
  }

  .new-items-image {
    display: block !important;
  }
  .img-new {
    width: 100% !important;
  }
  .img-new-2 {
    display: none !important;
  }
  .brands-container,
  .two-brands-container {
    display: block !important;
    padding-bottom: 0px !important;
  }
  .trend {
    margin-bottom: 54px !important;
  }
  .btn-cat-box {
    align-items: center;
    flex-direction: row !important;
    justify-content: space-between;
  }
  .trend-img,
  .brand-img {
    object-fit: cover !important;
    object-position: top;
  }
  .trend-name,
  .brand-name {
    font: normal 600 20px/26px DomaineSansText-Regular !important;
    margin-top: 16px !important;
  }
  .two-brands-container {
    margin: 48px 26px !important;
  }
  .brand {
    height: auto !important;
    margin-bottom: 40px;
  }
  .btn-brands {
    display: none;
  }
  .style-container {
    padding: 66px 25px !important;
  }
  .txt-title {
    font: normal 700 20px/26px DomaineSansText-Regular !important;
  }
  .div-styles {
    flex-wrap: wrap;
  }
  .style-box {
    padding: 0px !important;
    margin-right: 0px !important;

    width: 173px !important;
  }
  .look {
    height: 230px !important;
  }
}

/* User Account page */
@media screen and (max-width: 600px) {
  .user-account-container {
    margin: 22px !important;
    width: 90% !important;
  }
  .account-title {
    font: normal 700 20px/26px DomaineSansText-Regular !important;
  }
  .mobile-account-page {
    display: flex !important;
    margin-top: 27px;
  }
  .tabs-container {
    display: none !important;
  }
  .link-div {
    align-content: center;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    display: grid;
    height: 166px;
    place-items: center;
    padding: 10px;
    margin: 0px 15px 40px 0px;
  }
  @media screen and (max-width: 400px) {
    .link-div {
      margin: 0px 10px 40px 0px;
    }
  }
  .link-title {
    font: normal 700 16px/21px DomaineSansText-Regular !important;
    margin: 0px !important;
    text-align: center;
  }
  .link-desc {
    font: normal normal 6px/8px DomaineSansText-Regular !important;
    text-align: center;
    margin-top: 4px;
  }
}

/* Orders */
@media screen and (max-width: 600px) {
  .page-title {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    margin: 22px;
    width: calc(50vw + 2rem);
  }
  .back-icon {
    height: 15px;
  }
  .txt-title {
    margin-bottom: 0px !important;
  }
  .no-order-div {
    align-items: center;
    background: #000;
    display: flex;
    margin: 33px;
    padding: 10px;
    width: 84%;
  }
}
/* Saved Items */
@media screen and (max-width: 600px) {
  .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 40px 22px;
  }
  .item-container {
    width: 47%;
    margin-bottom: 56px;
  }
  .item-img {
    width: 100% !important;
  }
  .item-brand {
    color: #707070;
    font: normal normal 11px/14px DomaineSansText-Regular;
    margin-top: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;
    width: 100%;
  }
  .item-name {
    font: normal normal 13px/17px DomaineSansText-Regular;
  }

  .btn-add-to-bag,
  .size-select {
    font: normal normal 12px/16px DomaineSansText-Regular !important;
    height: 37px;
    width: 100%;
  }
  .btn-add-to-bag {
    margin-top: 10px;
  }
  .size-select.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    align-items: center;
    border: 1px solid #000000;
    height: 37px !important;
    margin-top: 0px !important;
  }
  .size-select .ant-select-arrow {
    top: 53% !important;
  }
  .add-to-cart-form .btn-add-to-bag {
    margin-top: 16px;
  }
  .btn-add-icon {
    margin-right: 15px !important;
    margin-top: 2px !important;
  }
}

/* address */
@media screen and (max-width: 600px) {
  .address-container {
    padding: 0px 22px;
    margin-bottom: 135px;
  }
  .address-div {
    background: #fff0f0;
    height: 260px;
    padding: 30px;
  }
  .txt-name {
    font: normal 700 24px/31px DomaineSansText-Regular !important;
  }
  .address {
    color: #000000;
    font: normal normal 18px/23px DomaineSansText-Regular !important;
  }
  .address-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .btn-address {
    height: 37px;
    width: 134px;
  }
  @media screen and (max-width: 400px) {
    .txt-name {
      font: normal 700 20px/31px DomaineSansText-Regular !important;
    }
    .address {
      color: #000000;
      font: normal normal 16px/23px DomaineSansText-Regular !important;
    }
    .btn-address {
      height: 37px;
      width: 120px;
    }
  }
  .btn-add-address {
    align-items: center;
    background: #ececee;
    display: flex;
    justify-content: center;
    font: normal normal 16px/22px DomaineSansText-Regular !important;
    height: 46px;
    margin-top: 4rem;
    width: 100%;
  }

  .address-form {
    margin: 30px;
  }
  .btn-new-address,
  .btn-cancel {
    margin-top: 12px;
    height: 46px;
    width: 100%;
  }

  /* Settings */
  @media screen and (max-width: 600px) {
    .collapse-container {
      margin-left: 12px;
      margin-right: 10px;
      margin-top: -1rem;
    }
    .panel-title {
      display: flex;
      align-items: center;
      font: normal normal 13px/17px DomaineSansText-Regular;
    }
    .panel-line {
      height: 1px;
      background-color: #dadada;
      flex: 1;
      margin-left: 10px;
    }
  }
}
.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 8rem !important;
}
/* 404 Page */
@media screen and (max-width: 600px) {
  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 8rem !important;
  }
  .wrapper-404 .content {
    margin-top:0 !important;
    transform: translate(-50%, -50%) !important;
  }
  .img-404 {
    height: 80px !important;
    width: 80px !important;
  }
  .txt-404 {
    font-size: 30px !important;
    line-height: 40px !important;
  }
  .txt-404-desc {
      font-size: 16px !important;
      line-height: 24px !important;
  }
  .btn-home {
    height: 48px !important;
    width: 90%;
  }
}

/* Product Details  */

/* mobile header */
@media screen and (max-width: 600px) {
  .mobile-shop-by-cat {
    margin-top: 43px;
  }
  .mobile-cat-img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
  .mobile-cat-img {
    height: 186px;
    object-fit: contain;
    object-position: center;
    width: -webkit-fill-available;
  }
  .mobile-cat-img-title {
    margin-top: 16px;
    margin-bottom: 5px;
    font: normal 600 14px/17px DomaineSansText-Regular;
  }
  .mobile-cat-img-desc {
    margin: 0px;
    font: normal normal 14px/16px DomaineSansText-Regular;
  }
}
/* Cart */
@media screen and (max-width: 600px) {
  .cart-img {
    height: 127px;
    width: 80px;
  }

  .cart-items-container {
    margin-left: 20px;
  }

  .cart-item-details {
    margin-left: 16px;
    margin-right: -10px;
  }

  .cart-item-modal .ant-modal-content {
    border-radius: 30px;
  }

  .cart-item-prompt {
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    place-items: center;
    justify-content: space-evenly;
    height: 250px;
  }

  .txt-cart-prompt {
    font: normal normal 16px/22px DomaineSansText-Regular;
    text-align: center;
  }

  .btn-cancel-modal {
    background: #f1f3f4;
    height: 52px;
    width: 100%;
  }

  .btn-del-cart-item {
    background: #ff1b36;
    height: 52px;
    width: 100%;
  }

  .cart-items-container
    .ant-select-single.ant-select-show-arrow
    .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding: 0px !important;
  }
  .checkout-items-container
    .ant-select-single.ant-select-show-arrow
    .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding: 10px !important;
  }
  .checkout-items-container
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 0px !important;
  }
  .shipping-details-container
    .ant-select-single.ant-select-show-arrow
    .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding: 5px !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding: 10px !important;
  }
  .shipping-details-container
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 0px !important;
  }

  .item-brand-name {
    color: #707070;
    font: normal normal 11px/14px DomaineSansText-Regular;
  }
  .cart-item-name {
    font: normal normal 13px/17px DomaineSansText-Regular;
    width: 200px;
  }
  .item-price {
    font: normal normal 14px/18px DomaineSansText-Regular;
  }

  .input-group {
    clear: both;
    position: relative;
    height: 37px;
    width: 108px;
  }

  .input-group input[type="button"] {
    border-top: 0.5px solid #000;
    border-bottom: 0.5px solid #000;
    transition: all 300ms ease;
  }

  .input-group .button-minus,
  .input-group .button-plus {
    background: transparent;
    height: 37px;
    padding: 0;
    width: 25px;
    position: relative;
  }

  .input-group .quantity-field {
    border-right: 0px;
    border-left: 0px;
    border-top: 0.5px solid #000;
    border-bottom: 0.5px solid #000;
    position: relative;
    height: 37px;
    left: -6px;
    text-align: center;
    width: 50px;
    display: inline-block;
    font-size: 13px;
    margin: 0px;
    background: transparent;
  }

  .button-plus {
    left: -13px;
    border-left: 0px;
    border-right: 0.5px solid #000;
  }
  .button-minus {
    border-right: 0px;
    border-left: 0.5px solid #000;
  }

  .checkout-container {
    background: #f3f3f3;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 36px;
  }
  .txt-subtotal {
    font: normal normal 12px/16px DomaineSansText-Regular;
  }
  .btn-checkout {
    font: normal normal 11px/14px DomaineSansText-Regular;
    height: 46px;
  }

  .link-coupon {
    font: normal normal 11px/14px DomaineSansText-Regular;
    text-align: center;
  }
}

/* Checkout */
@media screen and (max-width: 600px) {
  .mobile-checkout-container {
    display: flex;
  }
  .checkout-items-container {
    margin-left: 20px;
  }
  .delivery-address {
    margin-top: 137px;
    padding: 0px 22px;
  }
  .txt-checkout-address {
    font: normal normal 13px/17px DomaineSansText-Regular;
  }
  .radio-address {
    align-items: center;
    background: #fff0f0;
    height: 48px;
    margin-top: 10px;
    padding: 10px 30px;
  }
  .radio-address span.ant-radio + * {
    font: normal normal 11px/14px DomaineSansText-Regular;
  }
  .radio-address .ant-radio-inner {
    height: 16px;
    width: 16px;
  }
  .radio-address .ant-radio-checked .ant-radio-inner:after {
    top: 1px;
    left: 1px;
    height: 8px;
    width: 8px;
  }
  .txt-address-or::before {
    content: "";
    border: 0.5px solid #dadada;
    display: block;
    height: 0px;
    width: 133px;
  }
  .txt-address-or {
    align-items: center;
    display: flex;
    font: normal normal 13px/17px DomaineSansText-Regular;
    margin: 37px auto;
    justify-content: space-between;
    text-align: center;
    width: 80%;
  }
  .txt-address-or::after {
    content: "";
    border: 0.5px solid #dadada;
    display: block;
    height: 0px;
    width: 133px;
  }
  .delivery-address .btn-add-address {
    align-items: center;
    background: #ececee;
    display: flex;
    justify-content: center;
    font: normal normal 13px/17px DomaineSansText-Regular !important;
    height: 40px;
    margin-top: 0px;
    width: 100%;
  }
  .mobile-checkout-page .checkout-container {
    margin-top: 37px;
  }
}

/* Shipping */
@media screen and (max-width: 600px) {
  .mobile-shipping-container {
    margin: 22px;
    margin-right: 14px;
  }
  .shipping-details-container::after {
    content: "";
    display: block;
    height: 0px !important;
    width: 100%;
    border: 1px solid #f0e8e8;
    margin-top: 28px;
  }
  .index-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .txt-shipping-index {
    font: normal normal 13px/17px DomaineSansText-Regular;
  }
  .txt-shipping-state {
    color: #6f6f6f;
    font: normal normal 13px/17px DomaineSansText-Regular;
  }
  .txt-del-options {
    font: normal normal 13px/17px DomaineSansText-Regular;
    margin-top: 32px;
  }
  .color-box {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
  }
  .radio-del-options {
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 96px;
    margin-top: 10px;
    padding: 12px;
    width: 100%;
  }
  .txt-option-name {
    font: normal 600 14px/18px DomaineSansText-Regular;
  }
  .txt-option-price {
    color: #800000;
    font: normal bold 14px/18px DomaineSansText-Regular;
  }
  .del-option-desc {
    font: normal normal 9px/12px DomaineSansText-Regular;
  }
  .total-price-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/* Payment page */
@media screen and (max-width: 600px) {
  .mobile-payment-page {
    margin: 21px;
  }
  .txt-added-items,
  .txt-payment_type {
    font: normal 600 13px/17px DomaineSansText-Regular;
  }
  .checkout-item {
    display: flex;
    margin-top: 27px;
  }
  .mobile-payment-page::after {
    content: "";
    display: block;
    width: 90vw;
    border: 0.5px solid #c5c5c5;
    margin-top: 48px;
  }
  .item-details-row {
    align-items: center;
  }
  .txt-item-quantity {
    font: normal normal 19px/25px DomaineSansText-Regular;
  }
  .txt-item-size {
    border: 1px solid #000;
    font: normal normal 12px/16px DomaineSansText-Regular;
    padding: 9px;
  }
  .payment_type_container .txt-payment_type {
    margin-left: 36px;
    margin-top: 32px;
  }
  .payment_type_container .select {
    margin-left: 36px;
  }
  .payment_type_container .ant-select-arrow {
    top: 56%;
  }
}

/* Designers page */
@media screen and (max-width: 600px) {
  .txt-designer-title {
    color: #000000;
    font: normal 600 24px/31px DomaineSansText-Light;
    margin: 16px 13px;
  }
  .bg-designer {
    object-fit: cover;
    height: 124px;
    width: 100%;
  }
  .msearchbox {
    background: #f1f1f1;
    height: 52px;
    margin: 16px;
    width: 92%;
  }
  .mtabs {
    padding-left: 10px;
    margin-bottom: 5em;
  }
  .msearchbox .ant-input {
    background: #f1f1f1;
  }
  .mletter,
  .ant-tabs-tab-btn {
    color: #677189;
    font: normal normal 14px/146.16% DomaineSansText-Light !important ;
  }
  .mcontent {
    display: flex;
    flex-wrap: wrap;
    margin: 19px 25px;
  }
  .brands-div {
    margin-top: 0px !important;
    width: 50% !important;
  }
  .txt-designer-name {
    font: normal normal 13px/17px DomaineSansText-Light !important;
  }
}

/* seller dashoard Add product */
@media screen and (max-width: 600px) {
  .returns-div {
    float: left !important;
    margin-top: 5px;
  }
  .txt-return {
    font: normal 400 14px/23px DomaineSansText-Light !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


/* notification styles */
.losode-notif{
  background:transparent;
  padding: 0;
  font-family: 'DomaineSansText-Light';
  width: max-content;
  max-width: 600px;
}
.ant-notification-notice-message {
  display:none;
}
.ant-notification-notice {
  padding:0 !important;
  width: max-content !important;
}
.losode-notif .ant-notification-notice-description{
  padding: 0px 40px;
  color: white !important;
  display: flex;
  align-items: center;
  height: 100%;
  /* font-size: 16px; */
}
.losode-notif .ant-notification-notice-icon{
  padding: 16px 22px;
  background: white;
  margin: 0;
  display: flex;
  font-size: 28px;
  height: 100%;
}
.losode-notif .ant-notification-notice-with-icon{
  background: #000000;
  height: 54px;
}
.losode-notif .ant-notification-notice-close{
  color:white;
  display: none;
}

@media screen and (max-width: 600px) {
  .losode-notif{
    width: 100%;
  }
  .losode-notif .ant-notification-notice-description{
    font-size: 13px !important;
  }
}


/* form styles */

.ant-form-item-explain-error{
  font-family: DomaineSansText-Light;
}