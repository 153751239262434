.layout {
    align-items: center;
    background: #fff;
    display: flex;
    height: 96px;
    justify-content: space-between;
    padding: 0 40px !important;
}

.logo {
    cursor: pointer;
    width:200px;
}

.subText {
    font: normal normal 15px DomaineSansText-Regular;
    letter-spacing: 1.3px;
    color: #000000;
    text-align: center;
}
.links {
    display: flex;
    justify-content: space-evenly;
    margin-top: -0.5em;
  }
  .ant-menu-item a::before{
      display:none;
  }
